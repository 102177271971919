import React, { useEffect, useState, useCallback } from 'react';

import {
	Button,
	Modal,
	Table,
	Form,
	Input,
	Select,
	Row,
	Popconfirm,
	message,
} from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import propTypes from 'prop-types';

MerchantExternalIntegrations.propTypes = {
	merchantID: propTypes.string.isRequired,
};

export default function MerchantExternalIntegrations({ merchantID }) {
	const [loading, setLoading] = useState(false);
	const [choices, setChoices] = useState([]);
	const [externalIntegrations, setExternalIntegrations] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();

	const fetchExternalIntegrationData = useCallback(async () => {
		try {
			setLoading(true);
			const axios = await createCoreAxiosInstance();
			const {
				data: { choices, integrations },
			} = await axios.get(`/api/corpa/external-integration/${merchantID}/`);
			setChoices(choices.map(({ id, name }) => ({ value: id, label: name })));
			setExternalIntegrations(integrations);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, [merchantID]);

	useEffect(() => {
		if (!merchantID) return;
		fetchExternalIntegrationData();
	}, [merchantID, fetchExternalIntegrationData]);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const updateOrCreateExternalIntegrationData = useCallback(
		async (id, values) => {
			try {
				setLoading(true);
				const axios = await createCoreAxiosInstance();
				if (id) {
					await axios.patch(`api/corpa/external-integration-data/${id}/`, {
						...values,
					});
				} else {
					await axios.post(`api/corpa/external-integration-data/`, {
						...values,
					});
				}

				await fetchExternalIntegrationData();
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		},
		[fetchExternalIntegrationData],
	);

	const handleOk = async () => {
		try {
			const values = await form.validateFields();
			await updateOrCreateExternalIntegrationData(values.id, values);
			setIsModalOpen(false);
			form.resetFields();
			messageApi.info('Success!');
		} catch (error) {
			messageApi.error(`Failed: ${error}`);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		form.resetFields();
	};

	const merchantColumns = [
		{ key: 'id', dataIndex: 'id', title: 'ID' },
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'UUID',
			dataIndex: 'uuid',
			key: 'uuid',
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Popconfirm
					title="Add External integration Data?"
					description={`Add to ${record.name} - ${record.uuid}`}
					onConfirm={() => {
						form.setFieldsValue({
							external_integration_merchant: record.id,
						});
						showModal(record);
					}}
					okText="Yes"
					cancelText="No"
				>
					<Button type="link">Add</Button>
				</Popconfirm>
			),
		},
	];

	const createExternalIntegrationMerchant = useCallback(async () => {
		try {
			setLoading(true);
			const axios = await createCoreAxiosInstance();
			await axios.post(`/api/corpa/external-integration/${merchantID}/`);
			await fetchExternalIntegrationData();
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, [merchantID, fetchExternalIntegrationData]);

	const externalIntegrationMerchantDataColumns = [
		{
			title: 'External Integration Name',
			dataIndex: 'external_integration_name',
			key: 'external_integration_name',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'External Location ID',
			dataIndex: 'external_location_id',
			key: 'external_location_id',
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Popconfirm
					title={`Edit ${record.external_integration_name}`}
					onConfirm={() => {
						form.setFieldsValue({
							id: record.id,
							external_integration: record.external_integration,
							status: record.status,
							external_location_id: record.external_location_id,
						});
						showModal(record);
					}}
					okText="Yes"
					cancelText="No"
				>
					<Button type="link">Edit</Button>
				</Popconfirm>
			),
		},
	];

	return (
		<>
			{contextHolder}
			<Row className="mb-20">
				<Button
					type="primary"
					disabled={loading}
					onClick={createExternalIntegrationMerchant}
				>
					Create NEW External Integration Merchant
				</Button>
			</Row>

			<Table
				loading={loading}
				columns={merchantColumns}
				dataSource={externalIntegrations}
				rowKey={(record) => `${record.id}`}
				expandable={{
					expandedRowRender: (record) => {
						return (
							<Table
								columns={externalIntegrationMerchantDataColumns}
								dataSource={record?.integration_data ?? []}
								pagination={false}
							/>
						);
					},
				}}
				size="small"
				bordered
			/>
			<Modal
				title="External Integration"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Form form={form} layout="vertical">
					<Form.Item
						name="external_integration"
						label="External Integration"
						rules={[
							{
								required: true,
								message: 'Please select an external integration!',
							},
						]}
					>
						<Select placeholder="Select an integration" options={choices} />
					</Form.Item>

					<Form.Item
						name="status"
						label="Status"
						rules={[{ required: true, message: 'Please select a status!' }]}
					>
						<Select
							placeholder="Select a status"
							options={[
								{ value: 'active', label: 'Active' },
								{ value: 'inactive', label: 'Inactive' },
								{ value: 'pending', label: 'Pending' },
							]}
						/>
					</Form.Item>

					<Form.Item
						name="external_location_id"
						label="External Location ID"
						rules={[
							{ required: false, message: 'Please input the location ID!' },
							{
								type: 'string',
								max: 200,
								message: 'Location ID must be less than 200 characters!',
							},
						]}
					>
						<Input placeholder="Enter location ID" style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item name="id" hidden>
						<input type="hidden" />
					</Form.Item>
					<Form.Item name="external_integration_merchant" hidden>
						<input type="hidden" />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}
