/* eslint-disable react/display-name */
import React from 'react';

import { Card } from 'antd';
import MenuManagementAdminMerchant from 'menuManagement/MenuManagementAdminMerchant';
import MerchantExternalIntegrations from 'menuManagement/MerchantExternalIntegrations';

import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';

const routes = [
	{
		path: '/list/merchants',
		layout: 'list',
		sideMenu: true,
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
             query AllMerchants($limit: Int!, $offset: Int!, $search: String) {
                 merchants(limit: $limit, offset: $offset, search: $search) {
                     name,
                     uuid,
                     email,
                     id,
                     company {
                         id,
                         name
                     }
                     doordashCredentials: doordash {
                         id
                     }
                     moduurnCredentials: moduurn {
                         id
                     }
                     ritualCredentials: ritual {
                         id
                     }
                     grubhubCredentials: grubhub {
                         id
                     }
                     skipTheDishesCredentials: skip {
                         id
                     }
                     chownowCredentials: chownow {
                         id
                     }
                     freshbytesCredentials: freshbytes {
                         id
                     }
                     allsetCredentials: allset {
                         id
                     }
                     ubereatsCredentials: credential {
                         id
                     }
                 }
             }
         `,
			relations: [
				relation('company', 'single'),
				relation('doordashCredential', 'many'),
				relation('moduurnCredential', 'many'),
				relation('ritualCredential', 'many'),
				relation('grubhubCredential', 'many'),
				relation('skipTheDishesCredential', 'many'),
				relation('chownowCredential', 'many'),
				relation('freshbytesCredential', 'many'),
				relation('allsetCredential', 'many'),
				relation('ubereatsCredential', 'many'),
			],
			...relation('merchant', 'many'),
			excludeColumns: [
				'uuid',
				'id',
				'doordashCredentials',
				'moduurnCredentials',
				'ritualCredentials',
				'grubhubCredentials',
				'skipTheDishesCredentials',
				'chownowCredentials',
				'freshbytesCredentials',
				'allsetCredentials',
				'ubereatsCredentials',
			],
		},
	},
	{
		path: '/list/merchants/:id',
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
             query Merchant($id: Int!) {
                 merchant(id: $id) {
                     id
                     name
                     uuid
                     company {
                         id
                         name
                     }
                     doordashCredentials: doordash {
                         id
                     }
                     moduurnCredentials: moduurn {
                         id
                     }
                     ritualCredentials: ritual {
                         id
                     }
                     grubhubCredentials: grubhub {
                         id
                     }
                     skipTheDishesCredentials: skip {
                         id
                     }
                     chownowCredentials: chownow {
                         id
                     }
                     freshbytesCredentials: freshbytes {
                         id
                     }
                     allsetCredentials: allset {
                         id
                     }
                     ubereatsCredentials: credential {
                         id
                     }
                 }
              }
         `,
			relations: [
				relation('company', 'single'),
				relation('doordashCredential', 'many'),
				relation('moduurnCredential', 'many'),
				relation('ritualCredential', 'many'),
				relation('grubhubCredential', 'many'),
				relation('skipTheDishesCredential', 'many'),
				relation('chownowCredential', 'many'),
				relation('freshbytesCredential', 'many'),
				relation('allsetCredential', 'many'),
				relation('ubereatsCredential', 'many'),
			],
			...relation('merchant', 'single'),
			extra: (detail) => (
				<>
					<Card title="Menu Management Admin Panel">
						<MenuManagementAdminMerchant
							merchantId={detail?.id}
							companyId={detail?.company?.id}
							doorDashCredentials={detail?.doordashCredentials[0]?.id}
						/>
					</Card>
					<Card title="External Integrations">
						<MerchantExternalIntegrations merchantID={detail?.id} />
					</Card>
				</>
			),
		},
	},
];

export default routes;
